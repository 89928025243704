@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

body {
  font-size: 14px;
}

body.light-login {
  // background: url("../../images/background01.jpg") repeat scroll 0 0;
}

.page-content {
  padding: 0;
  background-color: #ccc;
}

.accordion-style1.panel-group .panel {
  margin-top: 0;
  border-top: 1px solid #ccc;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.accordion-style1.panel-group .panel+.panel {
  margin-top: 0;
  border-top: 1px solid #ccc;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

// $textColor =

.flex {
  display: flex!important;

  &.root-container {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  &.top {
    height: 59%;

    .top-container {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .subdomain-container {
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        min-width: 250px;
        scroll-behavior: auto;

        h2 {
          text-align: center;
          background-color: #eee;
          border-bottom: 1px solid #ccc;
          padding: 10px;
          margin-bottom: 0;
          margin-top: 0;
          font-weight: bold;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .sub-domain-wrapper {
          background: white;
          width: 250px;
          height: 100%;
          padding: 10px;

          .sub-domain {
            margin: 5px;
            border-radius: 10px;
            width: 120px;
            height: 120px;
            cursor: pointer;

            i {
              font-size: 45px;
              margin-top: 25px;
            }

            p {
              font-weight: bold;
              padding: 10px;
            }
          }

          .style1 {
            background-color: #EA9C4C;
          }

          .style2 {
            background-color: #8B2638;
          }

          .style3 {
            background-color: #A3C55B;
          }

          .style4 {
            background-color: #825B8D;
          }
        }
      }

      .svg-container {
        width: 100%;
        height: 100%;
        position: relative;
        // margin: auto;

        .svg {
          width: 100%;
          height: 100%;
        }

        .svg-container-footer {
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          right: 10px;

          button {
            width: 30%;
            justify-content: center;
          }

          .plus { background-color: #A3C55B!important; border-color: #A3C55B!important; }
          .reset { background-color: #EA9C4C!important; border-color: #EA9C4C!important; }
          .minus { background-color: #8B2638!important; border-color: #8B2638!important; }
        }
      }

      .device-container {
        min-width: 450px;
        flex-direction: column;
        height: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: white;

        h2 {
          text-align: center;
          background-color: #eee;
          border-bottom: 1px solid #ccc;
          padding: 10px;
          margin-bottom: 0;
          margin-top: 0;
          font-weight: bold;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .device-group-wrapper {
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          font-size: 14px;
          padding: 10px;
          cursor: pointer;

          .device-group-status {

            &.ok {
              color: #84BA5B;
            }

            &.ko {
              color: #D43B32;
            }

            &.warn {
              color: orange;
            }
          }

          .device-group-label {
            color: #393939;
            width: 125px;
          }

          i {
            font-size: 20px;
            margin-right: 10px;
          }

          .device-group-stats {
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            border: 2px solid #4E71B0;
            color: #4E71B0;
            width: 65px;
            padding: 2px;

            &.users {
              i {
                margin-right: 0;
                font-size: 18px;
                color: #4E71B0;
              }
            }

            &.time {
              background-color: #4E71B0;
              color: white;

              i {
                margin-right: 0;
                font-size: 18px;
                color: white;
              }

              span {
                color: white;
              }
            }
          }

          .device-group-actions {
            margin-left: 10px;
            cursor: pointer;

            i {
              color: #4E71B0;
              margin-right: 0;
            }
          }
        }

        .panel-body .device-wrapper {
          flex-direction: row;
          align-items: center;
          margin-top: 2px;

          &:last-child {

            .device-real-container {
              border-bottom: 0;
            }
          }

          .device-tab {
            width: 50px;
          }

          .device-real-container {
            flex-direction: row;
            align-items: center;
            padding-bottom: 2px;
            border-bottom: 1px solid #ccc;
            justify-content: space-around;
            width: 100%;
            cursor: pointer;

            .device-status {
              margin-right: 10px;

              &.ok { color: #84BA5B; }

              &.ko { color: #D43B32; }

              &.warn { color: orange; }
            }

            .device-label {
              color: #393939;
              width: 85px;
            }

            .device-stats {
              flex-direction: row;
              align-items: center;
              justify-content: space-evenly;
              border: 2px solid #4E71B0;
              color: #4E71B0;
              width: 100px;
              padding: 2px;

              &.users {
                i {
                  display: flex;
                  font-size: 18px;
                  color: #4E71B0;
                }

                span {
                  display: flex;
                }
              }

              &.time {
                background-color: #4E71B0;
                color: white;

                i {
                  display: flex;
                  font-size: 18px;
                  color: white;
                }

                span {
                  display: flex;
                  color: white;
                }
              }
            }

            .device-actions {
              margin-left: 10px;

              i {
                font-size: 20px;
                color: #4E71B0;
              }
            }
          }
        }
      }
    }
  }

  &.bottom {
    height: 40%;

    .bottom-container {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .round-top {
        flex-direction: column;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        width: 33%;
        height: 100%;
        background-color: white;

        h3 {
          background-color: #eee;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          margin: 0;
          padding: 10px;
          font-weight: bold;
          font-size: 18px;
          border-bottom: 2px solid #ccc;
        }

        &.diagnostic-container {

          .diagnostics-wrapper {
            flex-direction: column;

            .diagnostic-wrapper {
              flex-flow: row wrap;
              align-items: center;
              padding: 10px;
              border-bottom: 1px solid #ccc;

              &:last-child {
                border-bottom: 0;
              }

              .panel {
                margin-bottom: 0;
              }

              .panel-heading {
                padding: 5px 10px;
                background-color: #fff;
                border: none;

                h4 {
                  font-size: 14px;
                }
              }
              .panel-body {
                padding: 5px;

                .table {
                  margin-bottom: 0;
                }
              }

              .diagnostic-details {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                margin-right: 10px;
              }

              .diagnostic-status {
                font-size: 16px;
                margin-right: 10px;

                &.ok { color: #84BA5B; }

                &.ko { color: #D43B32; }

                &.warn { color: orange; }
              }

              .diagnostic-date {
                width: 100px;
                justify-content: center;
              }

              .diagnostic-time {
                width: 100px;
                justify-content: center;
              }

              .diagnostic-position {
                width: 100px;
                justify-content: center;
                font-weight: bold;
              }

              .diagnostic-ip {
                width: 100px;
                justify-content: center;
              }

              .diagnostic-description {
                justify-content: start;
                align-self: flex-end;
                font-size: 12px;
                font-style: italic;
              }
            }
          }
        }

        &.passage-container {
          #chart {
            height: 100%;
          }
        }

        &.alert-container {

          .alerts-wrapper {
            flex-direction: column;

            .alert-wrapper {
              font-size: 14px;
              flex-flow: row wrap;
              align-items: center;
              padding: 10px;
              padding-left: 30px;
              border-bottom: 1px solid #ccc;

              &:last-child {
                border-bottom: 0;
              }

              .panel {
                margin-bottom: 0;
              }

              .panel-heading {
                padding: 5px 10px;
                background-color: #fff;
                border: none;

                h4 {
                  font-size: 14px;
                }
              }
              .panel-body {
                padding: 5px;

                .table {
                  margin-bottom: 0;
                }
              }

              .alert-number {
                margin-left: 10px;
                width: 40px;
                justify-content: center;
              }
              .alert-name {
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

.clickable {
  &.no-show {
    fill: transparent!important;
    stroke: transparent!important;

    path {
      fill: transparent!important;
      stroke: transparent!important;
    }
  }

  &.unknown {
    fill: black!important;
    stroke: black!important;

    path {
      fill: black!important;
      stroke: black!important;
    }
  }

  &.ko {
    fill: #D43B32!important;
    stroke: #D43B32!important;

    path {
      fill: #D43B32!important;
      stroke: #D43B32!important;
    }
  }

  &.ok {
    fill: #84BA5B!important;
    stroke: #84BA5B!important;

    path {
      fill: #84BA5B!important;
      stroke: #84BA5B!important;
    }
  }

  &.warn {
    fill: orange!important;
    stroke: orange!important;

    path {
      fill: orange!important;
      stroke: orange!important;
    }
  }
}

.devices-details-wrapper {
  .ok {
    background-color: #84BA5B;
  }

  .ko {
    background-color: #D43B32;
  }

  .warn {
    background-color: orange;
  }
}

.custom-fieldset {
  padding: 15px;
  margin-top: 10px;
}
.custom-legend {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0 20px;
  width: auto;
}
.fieldset-border-blue {
  border: 1px solid #005270;
}

#blockModalLabel {
    .ok {
      color: #84BA5B;
    }

    .ko {
      color: #D43B32;
    }

    .warn {
      color: orange;
    }
}

/******** ELEMENTI NASCOSTI DALLA MAPPA PER ALLEGGERIRE *********/
#plus_pictos {
  display: none;
}
#divers {
  display: none;
}
#stades {
  display: none;
}
#navette {
  display: none;
}
#Z4ter {
  display: none;
}
#demis {
  display: none;
}
#Pictos {
  #navettes {
    display: none;
  }
  #picnic {
    display: none;
  }
  #arvas {
    display: none;
  }
  #info {
    display: none;
  }
  #hotels {
    display: none;
  }
  #luges {
    display: none;
  }
  #orientation {
    display: none;
  }
  #poi-health-09-01 {
    display: none;
  }
}

// EA9C4C arancio
// 8B2638 rosso
// A3C55B verde
// 825B8D viola

