@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

body {
  font-size: 14px;
}

.page-content {
  padding: 0;
  background-color: #ccc;
}

.accordion-style1.panel-group .panel {
  margin-top: 0;
  border-top: 1px solid #ccc;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.accordion-style1.panel-group .panel + .panel {
  margin-top: 0;
  border-top: 1px solid #ccc;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.flex {
  display: flex !important;
}

.flex.root-container {
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.flex.top {
  height: 59%;
}

.flex.top .top-container {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.flex.top .top-container .subdomain-container {
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-width: 250px;
  scroll-behavior: auto;
}

.flex.top .top-container .subdomain-container h2 {
  text-align: center;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.flex.top .top-container .subdomain-container .sub-domain-wrapper {
  background: white;
  width: 250px;
  height: 100%;
  padding: 10px;
}

.flex.top .top-container .subdomain-container .sub-domain-wrapper .sub-domain {
  margin: 5px;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  cursor: pointer;
}

.flex.top .top-container .subdomain-container .sub-domain-wrapper .sub-domain i {
  font-size: 45px;
  margin-top: 25px;
}

.flex.top .top-container .subdomain-container .sub-domain-wrapper .sub-domain p {
  font-weight: bold;
  padding: 10px;
}

.flex.top .top-container .subdomain-container .sub-domain-wrapper .style1 {
  background-color: #EA9C4C;
}

.flex.top .top-container .subdomain-container .sub-domain-wrapper .style2 {
  background-color: #8B2638;
}

.flex.top .top-container .subdomain-container .sub-domain-wrapper .style3 {
  background-color: #A3C55B;
}

.flex.top .top-container .subdomain-container .sub-domain-wrapper .style4 {
  background-color: #825B8D;
}

.flex.top .top-container .svg-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.flex.top .top-container .svg-container .svg {
  width: 100%;
  height: 100%;
}

.flex.top .top-container .svg-container .svg-container-footer {
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.flex.top .top-container .svg-container .svg-container-footer button {
  width: 30%;
  justify-content: center;
}

.flex.top .top-container .svg-container .svg-container-footer .plus {
  background-color: #A3C55B !important;
  border-color: #A3C55B !important;
}

.flex.top .top-container .svg-container .svg-container-footer .reset {
  background-color: #EA9C4C !important;
  border-color: #EA9C4C !important;
}

.flex.top .top-container .svg-container .svg-container-footer .minus {
  background-color: #8B2638 !important;
  border-color: #8B2638 !important;
}

.flex.top .top-container .device-container {
  min-width: 450px;
  flex-direction: column;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
}

.flex.top .top-container .device-container h2 {
  text-align: center;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.flex.top .top-container .device-container .device-group-wrapper {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-status.ok {
  color: #84BA5B;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-status.ko {
  color: #D43B32;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-status.warn {
  color: orange;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-label {
  color: #393939;
  width: 125px;
}

.flex.top .top-container .device-container .device-group-wrapper i {
  font-size: 20px;
  margin-right: 10px;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-stats {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid #4E71B0;
  color: #4E71B0;
  width: 65px;
  padding: 2px;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-stats.users i {
  margin-right: 0;
  font-size: 18px;
  color: #4E71B0;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-stats.time {
  background-color: #4E71B0;
  color: white;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-stats.time i {
  margin-right: 0;
  font-size: 18px;
  color: white;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-stats.time span {
  color: white;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-actions {
  margin-left: 10px;
  cursor: pointer;
}

.flex.top .top-container .device-container .device-group-wrapper .device-group-actions i {
  color: #4E71B0;
  margin-right: 0;
}

.flex.top .top-container .device-container .panel-body .device-wrapper {
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
}

.flex.top .top-container .device-container .panel-body .device-wrapper:last-child .device-real-container {
  border-bottom: 0;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-tab {
  width: 50px;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container {
  flex-direction: row;
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 1px solid #ccc;
  justify-content: space-around;
  width: 100%;
  cursor: pointer;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-status {
  margin-right: 10px;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-status.ok {
  color: #84BA5B;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-status.ko {
  color: #D43B32;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-status.warn {
  color: orange;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-label {
  color: #393939;
  width: 85px;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-stats {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid #4E71B0;
  color: #4E71B0;
  width: 100px;
  padding: 2px;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-stats.users i {
  display: flex;
  font-size: 18px;
  color: #4E71B0;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-stats.users span {
  display: flex;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-stats.time {
  background-color: #4E71B0;
  color: white;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-stats.time i {
  display: flex;
  font-size: 18px;
  color: white;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-stats.time span {
  display: flex;
  color: white;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-actions {
  margin-left: 10px;
}

.flex.top .top-container .device-container .panel-body .device-wrapper .device-real-container .device-actions i {
  font-size: 20px;
  color: #4E71B0;
}

.flex.bottom {
  height: 40%;
}

.flex.bottom .bottom-container {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.flex.bottom .bottom-container .round-top {
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 33%;
  height: 100%;
  background-color: white;
}

.flex.bottom .bottom-container .round-top h3 {
  background-color: #eee;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  border-bottom: 2px solid #ccc;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper {
  flex-direction: column;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper {
  flex-flow: row wrap;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper:last-child {
  border-bottom: 0;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .panel {
  margin-bottom: 0;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .panel-heading {
  padding: 5px 10px;
  background-color: #fff;
  border: none;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .panel-heading h4 {
  font-size: 14px;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .panel-body {
  padding: 5px;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .panel-body .table {
  margin-bottom: 0;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-details {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-status {
  font-size: 16px;
  margin-right: 10px;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-status.ok {
  color: #84BA5B;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-status.ko {
  color: #D43B32;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-status.warn {
  color: orange;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-date {
  width: 100px;
  justify-content: center;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-time {
  width: 100px;
  justify-content: center;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-position {
  width: 100px;
  justify-content: center;
  font-weight: bold;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-ip {
  width: 100px;
  justify-content: center;
}

.flex.bottom .bottom-container .round-top.diagnostic-container .diagnostics-wrapper .diagnostic-wrapper .diagnostic-description {
  justify-content: start;
  align-self: flex-end;
  font-size: 12px;
  font-style: italic;
}

.flex.bottom .bottom-container .round-top.passage-container #chart {
  height: 100%;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper {
  flex-direction: column;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper {
  font-size: 14px;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
  border-bottom: 1px solid #ccc;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper:last-child {
  border-bottom: 0;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper .panel {
  margin-bottom: 0;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper .panel-heading {
  padding: 5px 10px;
  background-color: #fff;
  border: none;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper .panel-heading h4 {
  font-size: 14px;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper .panel-body {
  padding: 5px;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper .panel-body .table {
  margin-bottom: 0;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper .alert-number {
  margin-left: 10px;
  width: 40px;
  justify-content: center;
}

.flex.bottom .bottom-container .round-top.alert-container .alerts-wrapper .alert-wrapper .alert-name {
  justify-content: center;
}

.clickable.no-show {
  fill: transparent !important;
  stroke: transparent !important;
}

.clickable.no-show path {
  fill: transparent !important;
  stroke: transparent !important;
}

.clickable.unknown {
  fill: black !important;
  stroke: black !important;
}

.clickable.unknown path {
  fill: black !important;
  stroke: black !important;
}

.clickable.ko {
  fill: #D43B32 !important;
  stroke: #D43B32 !important;
}

.clickable.ko path {
  fill: #D43B32 !important;
  stroke: #D43B32 !important;
}

.clickable.ok {
  fill: #84BA5B !important;
  stroke: #84BA5B !important;
}

.clickable.ok path {
  fill: #84BA5B !important;
  stroke: #84BA5B !important;
}

.clickable.warn {
  fill: orange !important;
  stroke: orange !important;
}

.clickable.warn path {
  fill: orange !important;
  stroke: orange !important;
}

.devices-details-wrapper .ok {
  background-color: #84BA5B;
}

.devices-details-wrapper .ko {
  background-color: #D43B32;
}

.devices-details-wrapper .warn {
  background-color: orange;
}

.custom-fieldset {
  padding: 15px;
  margin-top: 10px;
}

.custom-legend {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0 20px;
  width: auto;
}

.fieldset-border-blue {
  border: 1px solid #005270;
}

#blockModalLabel .ok {
  color: #84BA5B;
}

#blockModalLabel .ko {
  color: #D43B32;
}

#blockModalLabel .warn {
  color: orange;
}

/******** ELEMENTI NASCOSTI DALLA MAPPA PER ALLEGGERIRE *********/
#plus_pictos {
  display: none;
}

#divers {
  display: none;
}

#stades {
  display: none;
}

#navette {
  display: none;
}

#Z4ter {
  display: none;
}

#demis {
  display: none;
}

#Pictos #navettes {
  display: none;
}

#Pictos #picnic {
  display: none;
}

#Pictos #arvas {
  display: none;
}

#Pictos #info {
  display: none;
}

#Pictos #hotels {
  display: none;
}

#Pictos #luges {
  display: none;
}

#Pictos #orientation {
  display: none;
}

#Pictos #poi-health-09-01 {
  display: none;
}
